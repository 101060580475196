import React from 'react';
import TranslationUI from './extra.js';

function App() {
  return (
    <div className="App flex flex-col min-h-screen">
      
      <main className="flex-grow w-full px-0 py-0">
        <TranslationUI />
      </main>
      <footer className="bg-blue-600 text-white py-4">
        <div className="container mx-auto text-center text-white-100">
          <p>&copy; 2024 Translation Tool. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
